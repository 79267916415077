<template>
    <div class="rooms-card__top">
        <input type="checkbox" @click="selectAllRooms" class="ready-for-inspection__checkbox" v-model="selectAllCheck" v-if="rooms.length">
        Rooms Ready For Inspection
    </div>

    <div class="ready-for-inspection">
        <div class="ready-for-inspection__room" v-for="(room, index) in rooms" :key="room.id">
            <input type="checkbox" :value="room.id" class="ready-for-inspection__checkbox" v-model="selectedRooms" @change="checkSelectedRooms">
            <span>Floor {{ room.floorName }} {{ room.floorPrefix }}</span> -
            <span>Room {{ room.roomName }}</span>
        </div>

        <div class="ready-for-inspection__empty-list" v-if="!rooms.length">
            <img src="@/assets/icons/vuesax/linear/task.svg" alt="" width="15" height="15">
            There are currently no rooms to inspect...
        </div>
    </div>

    <button class="button button button--primary button--small-padding" @click="showModal = true" :disabled="!selectedRooms.length" v-if="rooms.length">
        Confirm Inspection
    </button>

    <ModalInspection :rooms="selectedRooms" :show-modal="showModal" @update-list="updateRoomsLis" @close-modal="closeModal"/>
</template>

<script setup>
import { readyForInspectionServices } from '../Services/ReadyForInspectionServices'
import { onMounted, ref } from 'vue'
import { useClientStore } from '../../../stores/ClientStore'
import ModalInspection from '../Components/ModalInspection.vue'

const { getRoomsService } = readyForInspectionServices()
const { client } = useClientStore()
const rooms = ref([])
const selectedRooms = ref([])
const selectAllCheck = ref(false)
const showModal = ref(false)

onMounted(() => {
    getRooms()
    readyForInspectionEvent()
})

// * Join in rooms-inspections event 
async function readyForInspectionEvent() {
    Echo.private(`rooms-inspections.${client.hotelId}`)
        .listen(".ready-for-inspection", (room) => {
            getRooms()
        })
}

async function getRooms() {
    const response = await getRoomsService()

    if (response.hasErrors) return console.log(`Error getting rooms ready for inspection: ${response.message}`)

    rooms.value = response.data
}

async function updateRoomsLis() {
    selectAllCheck.value = false
    selectedRooms.value = []

    await getRooms()
}

function selectAllRooms(e) {
    const isChecked = e.target.checked

    selectedRooms.value = []

    if (isChecked) rooms.value.forEach(room => { selectedRooms.value.push(room.id) })
    else selectedRooms.value = []
}

function checkSelectedRooms() {
    if (rooms.value.length === selectedRooms.value.length) selectAllCheck.value = true
    if (rooms.value.length !== selectedRooms.value.length) selectAllCheck.value = false
}

function closeModal(value) {
    showModal.value = value
}
</script>
import { httpRequests } from '../../../helpers/HttpRequests.js'

export function associatesServices() {
    const { getRequest, postFormRequest, putRequest, httpErrors, resetErrors } = httpRequests();
    const errors = httpErrors

    function getAssociatesService() {
        return getRequest('');
    }

    function addAssociateService(associate) {
        return postFormRequest('/web/associates/add', associate)
    }

    function editAssociateService(associateId) {
        return getRequest(`/web/associates/edit/${associateId}`)
    }

    function updateAssociateService(associateId, associate) {
        return putRequest(`/web/associates/update/${associateId}`, associate)
    }

    function updateAssociateImageService(associateId, associateImage) {
        return postFormRequest(`/web/associates/update/image/${associateId}`, associateImage)
    }

    function getAssociateRoomsLogsService(page) {
        return getRequest(page)
    }

    function importAssociatesService(excelFile) {
        return postFormRequest('/web/associates/import', excelFile)
    }

    return {
        getAssociatesService,
        addAssociateService,
        editAssociateService,
        updateAssociateService,
        updateAssociateImageService,
        getAssociateRoomsLogsService,
        importAssociatesService,
        errors,
        resetErrors
    }
}
import { httpRequests } from "../../../helpers/HttpRequests"

export function readyForInspectionServices() {
    const { getRequest, putRequest } = httpRequests()

    function getRoomsService() {
        return getRequest('/web/rooms/ready_for_inspection')
    }

    function updateRoomsStatusService(rooms) {
        return putRequest(`/web/rooms/update_status`, rooms)
    }

    return { getRoomsService, updateRoomsStatusService }
}